@import "../../../styles/variables.module";

.back_container {
  padding: 3.2rem 3.2rem 1.6rem;
  margin: 0 auto;
  position: relative;

  @include media(">desktop720p") {
    padding: 3.2rem 5rem 1.6rem;
  }
  @include media("<=tablet") {
    padding: 3.2rem 1.6rem 1.6rem;
  }
}

.container {
  max-width: 56rem;
  margin: 6.4rem auto;
  position: relative;

  @include media("<=tablet") {
    padding: 0 1.6rem;
  }

  h2 {
    color: $color-light;
    text-align: center;
    font-size: 6.4rem;
    margin-bottom: 6.4rem;

    @include media("<=tablet") {
      font-size: 4.2rem;
      line-height: 3.2rem;
      margin-bottom: 4.2rem;
    }
  }

  .form_group {
    margin-bottom: 4.8rem;
  }
}

.accept_terms {
  color: $color-light;
  font-size: 1.6rem;
  padding: 1.6rem 0 1.6rem;
  text-align: center;
}

.facebook_btn_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4.2rem;

  .facebook_btn {
    background-color: #1a77f2;
    font-size: 2rem;
    width: unset;
    padding: 0.8rem 1.2rem;

    img {
      margin-right: 0.8rem;
      width: 2.8rem;
      height: 2.8rem;
    }
  }
}

