@import "../../../styles/variables.module";

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .forgot_button {
      &:focus {
        outline: none;
        background-color: rgba(93, 108, 146, 0.35);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.05);
      }
    }
  }
}

body:global(.low-performance) {
  .forgot_button {
    transition: none !important;
  }
}

.container {
  max-width: 56rem;
  margin: 6.4rem auto;
  position: relative;
  
  @include media("<=tablet") {
    margin-top: 2.4rem;
  }

  h2 {
    color: $color-light;
    text-align: center;
    font-size: 6.4rem;
    margin-bottom: 6.4rem;

    @include media("<=tablet") {
      font-size: 4.2rem;
      line-height: 3.2rem;
      margin-bottom: 4.2rem;
    }
  }
  .form_group {
    margin-bottom: 4.8rem;
  }

  .forgot_button {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-decoration: underline;
    transition: 0.5s all;
    border-radius: 16px;
  }
}

.loader_wrapper {
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 7.2rem;
    height: 7.2rem;
  }
}

.facebook_btn_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4.2rem;

  .facebook_btn {
    background-color: #1a77f2;
    font-size: 2rem;
    width: unset;
    padding: 0.8rem 1.2rem;

    img {
      margin-right: 0.8rem;
      width: 2.8rem;
      height: 2.8rem;
    }
  }
}
