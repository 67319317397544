@import "../../../styles/variables.module";

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .back {
      &:focus {
        outline: none;
        background-color: rgba(93, 108, 146, 0.35);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.05);
      }
    }
  }
}

body:global(.low-performance) {
  .back,
  .forgot_button {
    transition: none !important;
  }
}

.back {
  height: 6rem;
  align-items: center;
  display: flex;
  transition: 0.5s all;
  border-radius: $border-radius-small;
  margin: -1.6rem;
  padding: 0 1.6rem;

  span {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.02em;
    color: $color-light;
    margin-left: 0.8rem;

    html[dir="rtl"] & {
      margin-right: 0.8rem;
      margin-left: 0;
    }
  }

  svg {
    width: 6rem;
    height: 6rem;
    margin-left: -0.8rem;
  }

  @include media("<tablet") {
    span {
      font-size: 2.4rem;
    }
    svg {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}

.wrapper {
  padding: 4.2rem 3.2rem 1.6rem;
  margin: 0 auto;
  position: relative;

  &.absolute {
    position: absolute;
    top: -2.4rem;
  }

  @include media(">desktop720p") {
    padding: 4.2rem 5rem 1.6rem;
  }
  @include media("<=tablet") {
    padding: 3.2rem 2.4rem 1.6rem;
  }
}
