@import "../../../styles/variables.module";


.container {
  max-width: 65rem;
  margin: 6.4rem auto;
  position: relative;

  h2 {
    color: $color-light;
    text-align: center;
    font-size: 6.4rem;
    margin-bottom: 6.4rem;
  }

  .form_group {
    margin-bottom: 4.8rem;
  }

  .success_info {
    color: $color-light;
    font-size: 2.4rem;

    p {
      padding: 0 0 4.8rem;
      text-align: center;
    }
  }

  .loader_wrapper {
    padding: 4.8rem 3.2rem;
    display: flex;
    justify-content: center;
    svg {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
}
