@import "../../../styles/variables.module";

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .input__control {
      transition: 250ms all;

      &:focus {
        outline: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.01);
      }
    }
  }
}

body:global(.low-performance) {
  .input__control {
    transition: none !important;
  }
}

.input {
  position: relative;

  &__label {
    display: block;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    &.error {
      color: $color-danger;
    }
  }
  &__control {
    border: none;
    font-size: 2.4rem;
    font-weight: 600;
    background-color: rgba($color-light, 0.15);
    border-radius: 8px;
    color: $color-light;
    width: 100%;
    padding: 2.4rem 3.2rem;
    resize: none;

    @include media("<=tablet") {
      padding: 1.6rem 2.4rem;
    }

    &::placeholder {
      color: $color-light;
    }

    &:focus {
      outline: none;
    }

    &.error {
      border: 1px solid $color-danger;
    }

    &.active {
      outline: auto;
    }
  }
  .icon {
    position: absolute;
    bottom: 1.2rem;
    right: 0;
    width: 2.4rem;
    height: 2.4rem;
  }
}
.message {
  display: block;
  font-size: 1.2rem;
  margin-top: 0.5rem;

  &_error {
    color: $color-danger;
    font-size: 1.6rem;
  }
}
