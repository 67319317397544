@import "../../../styles/variables.module";

.container {
  max-width: calc(100vw - 10rem);
  margin: 0 auto 4.4rem;
  color: $color-light;
  position: relative;
  z-index: 1;
  padding-top: 3.2rem;

  @include media("<tablet") {
    max-width: calc(100vw - 3.2rem);
  }

  h2 {
    color: $color-light;
    text-align: center;
    font-size: 6.4rem;
    margin-bottom: 6.4rem;

    @include media("<=tablet") {
      font-size: 4.2rem;
      line-height: 3.2rem;
      margin-bottom: 4.2rem;
    }
  }
  
  .form_group {
    margin-bottom: 4.8rem;
  }

  .code_wrapper {
    text-align: center;
  }
  .code {
    border: 2px dashed $color-light;
    font-size: 12rem;
    font-weight: 500;
    line-height: 12rem;
    letter-spacing: 0.2em;
    padding: 3rem 5rem;
    border-radius: 33px;
    display: inline-block;

    @include media("<tablet") {
      font-size: 6rem;
      padding: 2rem 3rem;
    }
  }

  .instruction {
    margin-top: 8rem;

    h3 {
      font-size: 3.6rem;
      font-weight: 700;
      line-height: 3.6rem;
      letter-spacing: 0.2em;
      text-align: center;
      text-transform: uppercase;

      @include media("<tablet") {
        font-size: 2.4rem;
      }
    }

    .steps {
      width: 90%;
      margin: 6rem auto 2rem;
      display: flex;
      justify-content: space-between;

      @include media("<desktop720p") {
        align-content: center;
        flex-direction: column;
        width: 100%;
      }

      .step {
        flex-basis: 30%;
        
        @include media("<desktop720p") {
          margin-bottom: 3rem;
        }

        .number {
          width: 6.5rem;
          height: 6.5rem;
          border-radius: 50%;
          margin: 0 auto 2rem;
          border: 3px solid rgba($color-light, 0.2);
          text-align: center;
          font-size: 3.3rem;
          font-weight: 900;
          line-height: 6rem;
        }
        p {
          font-size: 3.3rem;
          line-height: 3.9rem;
          text-align: center;

          @include media("<tablet") {
            font-size: 2.4rem;
            line-height: 2.9rem;
          }
        }
      }
    }
  }
}
